// getNextSymbol takes a string and an array of symbols and finds the first occurrence of one of these symbols. Optionally, a startFrom can be added to start searching from this index. The result will be greater or equal than this number. Returns -1 if none of the symbols are found.
function getNextSymbol(str, symbols, startFrom = 0) {
	return symbols.reduce((result, symbol) => {
		const nextOccurrence = str.indexOf(symbol, startFrom)
		if (nextOccurrence === -1)
			return result
		if (result === -1)
			return nextOccurrence
		return Math.min(result, nextOccurrence)
	}, -1)
}
module.exports.getNextSymbol = getNextSymbol

// getTagTree takes a string, like "My name is <strong><link>{firstName}</link></strong> and I'm <em>{years}</em> years old." It turns this into a tag tree of the form [{type: 'text', value: 'My name is '}, {type: 'tag', name: 'strong', value: [{type: 'tag', name: 'link', value: [{type: 'variable', name: 'firstName'}]}]}, {type: 'text', value: ' and I\'m '}, {type: 'tag', name: 'em', value: [{type: 'variable', name: 'years'}]}, {type: 'text', value: ' years old.'}].
const tagRegEx = /\{([a-zA-Z0-9-]+)\}|<([a-zA-Z0-9-]+)>|<\/([a-zA-Z0-9-]+)>|<([a-zA-Z0-9-]+)\/>/ // Variable, open-tag, close-tag or self-tag.
function getTagTree(originalStr) {
	const stack = []
	const mainList = []
	let currentList = mainList

	// While we can find a variable or tag, keep adding it.
	let str = originalStr
	let search = str.match(tagRegEx)
	while (search) {
		// Add the prior string to the result.
		if (search.index > 0)
			currentList.push({ type: 'text', value: str.substring(0, search.index) })

		// Determine the type of tag.
		if (search[1] !== undefined) { // Variable: add it to the list.
			currentList.push({ type: 'variable', name: search[1] })
		} else if (search[2] !== undefined) { // Open-tag: add it to the list and add it to the stack so we know what to expect on a closing tag.
			const tagObj = { type: 'tag', name: search[2], value: [] }
			stack.push(tagObj)
			currentList.push(tagObj)
			currentList = tagObj.value
		} else if (search[3] !== undefined) { // Close-tag: check if it matches the expectation and go down one from the stack.
			const tag = stack.pop()
			if (tag.name !== search[3])
				throw new Error(`Invalid tag string: an opened tag <${tag.name}> was closed by </${search[3]}>. Make sure tags are properly nested. The full string is:\n${originalStr}`)
			currentList = stack.length > 0 ? stack[stack.length - 1].value : mainList

			// On no or only one value in the list, replace the list by either undefined or said item.
			const lastObject = currentList[currentList.length - 1]
			if (lastObject.value.length === 0)
				delete lastObject.value
			else if (lastObject.value.length === 1)
				lastObject.value = lastObject.value[0]
		} else if (search[4] !== undefined) { // Self-tag: add it to the list.
			currentList.push({ type: 'tag', name: search[4] })
		} else {
			throw new Error(`Invalid tag string: encountered an impossible case while getting the tag tree of a string. The full string is:\n${originalStr}`)
		}

		// Throw out the processed part of the string, keeping the rest.
		str = str.substring(search.index + search[0].length)

		// Find the next variable/tag for the next iteration.
		search = str.match(tagRegEx)
	}

	// No more variables or tags. Verify that the stack is empty.
	if (stack.length > 0)
		throw new Error(`Invalid tag string: an opened tag <${lastOf(stack).name}> was never closed. The full string is:\n${originalStr}`)

	// All in order. Add the last string.
	if (str.length > 0)
		mainList.push({ type: 'text', value: str })

	// On a single element, return said element.
	if (mainList.length === 1)
		return mainList[0]
	return mainList
}
module.exports.getTagTree = getTagTree

// tagTreeToString takes a tag tree (possibly a sub-tree generated by getTagTree) and turns it back into a string.
function tagTreeToString(tagTree) {
	return tagTree.map(tagTreeItem => {
		// On a text, return the string.
		if (tagTreeItem.type === 'text')
			return tagTreeItem.value

		// On a variable, return the variable as encoded.
		if (tagTreeItem.type === 'variable')
			return `{${tagTreeItem.name}}`

		// On a tag return either a self-tag (on no value) or a regular tag (on a value) with its contents.
		if (tagTreeItem.type === 'tag') {
			if (!tagTreeItem.value)
				return `<${tagTreeItem.name}/>`
			return `<${tagTreeItem.name}>${tagTreeToString(tagTreeItem.value)}</${tagTreeItem.name}>`
		}

		// Impossible case.
		throw new Error(`Invalid tag tree: encountered an element in a tag tree with type "${tagTreeItem.type}". This is not a valid type.`)
	}).join('')
}
module.exports.tagTreeToString = tagTreeToString
