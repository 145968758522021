import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function Sqrt(props) {
	// First the x, then the top bar and then the root symbol. Add stroke to make it more bold-like and hence visible.
	return (
		<SvgIcon {...props}>
			<path d="M 18.345 13.575 c -0.15 0.75 -0.3 1.05 -0.3 1.5 c 0 0.6 0.3 1.05 0.9 1.05 c 0.45 0 1.65 -0.45 2.25 -2.1 c 0 -0.15 0 -0.15 0.15 -0.15 c 0 0 0.15 0 0.15 0.15 c 0 0.45 -0.9 2.55 -2.55 2.55 c -1.05 0 -1.5 -0.6 -1.8 -1.2 c 0 0.15 -0.6 1.2 -1.65 1.2 c -0.75 0 -1.5 -0.3 -1.5 -1.05 c 0 -0.45 0.45 -0.9 0.9 -0.9 c 0.3 0 0.6 0.15 0.6 0.6 c 0 0.45 -0.3 0.75 -0.75 0.9 c 0.3 0.15 0.75 0.15 0.75 0.15 c 0.75 0 1.35 -0.75 1.5 -1.65 c 0.6 -2.25 0.9 -3.45 0.9 -3.9 c 0 -0.9 -0.6 -1.05 -0.9 -1.05 c -0.6 0 -1.65 0.45 -2.25 2.1 c 0 0.15 -0.15 0.15 -0.15 0.15 c 0 0 -0.15 0 -0.15 -0.15 c 0 -0.45 0.9 -2.55 2.7 -2.55 c 0.45 0 1.35 0.15 1.8 1.2 c 0.15 -0.45 0.75 -1.2 1.65 -1.2 c 0.6 0 1.5 0.3 1.5 1.05 c 0 0.6 -0.45 0.9 -0.9 0.9 c -0.45 0 -0.6 -0.3 -0.6 -0.6 c 0 -0.45 0.3 -0.75 0.75 -0.9 c -0.3 -0.15 -0.75 -0.15 -0.75 -0.15 c -1.2 0 -1.5 1.5 -1.65 1.95 L 18.345 13.575 z" fill="currentColor" stroke="currentColor" strokeWidth="0.5" />
			<rect x="13.395" y="3.825" width="9.3" height="0.6" fill="currentColor" stroke="currentColor" strokeWidth="1" />
			<path d="M 13.095 4.125 c 0.15 -0.3 0.3 -0.3 0.45 -0.3 c 0.15 0 0.3 0.15 0.3 0.3 c 0 0 0 0.15 -0.15 0.3 l -7.5 15.45 c -0.15 0.3 -0.15 0.3 -0.45 0.3 c -0.15 0 -0.3 0 -0.3 -0.3 l -3.15 -7.05 c -0.15 0.15 -0.45 0.3 -0.45 0.45 c -0.15 0.15 -0.45 0.3 -0.6 0.3 c -0.15 0 -0.15 -0.15 -0.15 -0.15 c 0 0 0 -0.15 0.15 -0.3 l 1.5 -1.2 c 0.15 -0.15 0.3 -0.15 0.3 -0.15 c 0 0 0.15 0 0.3 0.3 l 2.85 6.3 L 13.095 4.125 z" fill="currentColor" stroke="currentColor" strokeWidth="1" />
		</SvgIcon>
	)
}
