const { FloatUnit } = require('../../inputTypes')

// Critical point data.
const criticalPoint = {
	pressure: new FloatUnit({ float: '40.592', unit: 'bar' }),
	temperature: new FloatUnit({ float: '101.061', unit: 'dC' }),
	enthalpy: new FloatUnit({ float: '389.64', unit: 'kJ/kg' }),
	entropy: new FloatUnit({ float: '1.5621', unit: 'kJ/kg * K' }),
}
module.exports.criticalPoint = criticalPoint

// Boiling data.
const temperature = ['-41.000', '-40.000', '-39.000', '-38.000', '-37.000', '-36.000', '-35.000', '-34.000', '-33.000', '-32.000', '-31.000', '-30.000', '-29.000', '-28.000', '-27.000', '-26.000', '-25.000', '-24.000', '-23.000', '-22.000', '-21.000', '-20.000', '-19.000', '-18.000', '-17.000', '-16.000', '-15.000', '-14.000', '-13.000', '-12.000', '-11.000', '-10.000', '-9.0000', '-8.0000', '-7.0000', '-6.0000', '-5.0000', '-4.0000', '-3.0000', '-2.0000', '-1.0000', '0.0000', '1.0000', '2.0000', '3.0000', '4.0000', '5.0000', '6.0000', '7.0000', '8.0000', '9.0000', '10.000', '11.000', '12.000', '13.000', '14.000', '15.000', '16.000', '17.000', '18.000', '19.000', '20.000', '21.000', '22.000', '23.000', '24.000', '25.000', '26.000', '27.000', '28.000', '29.000', '30.000', '31.000', '32.000', '33.000', '34.000', '35.000', '36.000', '37.000', '38.000', '39.000', '40.000', '41.000', '42.000', '43.000', '44.000', '45.000', '46.000', '47.000', '48.000', '49.000', '50.000', '51.000', '52.000', '53.000', '54.000', '55.000', '56.000', '57.000', '58.000', '59.000', '60.000', '61.000', '62.000', '63.000', '64.000', '65.000', '66.000', '67.000', '68.000', '69.000', '70.000', '71.000', '72.000', '73.000', '74.000', '75.000', '76.000', '77.000', '78.000', '79.000', '80.000', '81.000', '82.000', '83.000', '84.000', '85.000', '86.000', '87.000', '88.000', '89.000', '90.000', '91.000', '92.000', '93.000', '94.000', '95.000', '96.000', '97.000', '98.000', '99.000', '100.00', '101.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact())
temperature.push(criticalPoint.temperature)

const pressure = ['0.48577', '0.51209', '0.53955', '0.56817', '0.59801', '0.62908', '0.66144', '0.69512', '0.73015', '0.76658', '0.80444', '0.84378', '0.88463', '0.92703', '0.97104', '1.0167', '1.0640', '1.1130', '1.1639', '1.2165', '1.2710', '1.3273', '1.3857', '1.4460', '1.5084', '1.5728', '1.6394', '1.7082', '1.7792', '1.8524', '1.9280', '2.0060', '2.0864', '2.1693', '2.2548', '2.3428', '2.4334', '2.5268', '2.6228', '2.7217', '2.8234', '2.9280', '3.0356', '3.1462', '3.2598', '3.3766', '3.4966', '3.6198', '3.7463', '3.8761', '4.0094', '4.1461', '4.2863', '4.4301', '4.5776', '4.7288', '4.8837', '5.0425', '5.2052', '5.3718', '5.5424', '5.7171', '5.8959', '6.0789', '6.2662', '6.4578', '6.6538', '6.8543', '7.0592', '7.2688', '7.4830', '7.7020', '7.9257', '8.1543', '8.3878', '8.6263', '8.8698', '9.1185', '9.3724', '9.6315', '9.8960', '10.166', '10.441', '10.722', '11.009', '11.301', '11.599', '11.903', '12.213', '12.529', '12.851', '13.179', '13.513', '13.854', '14.201', '14.555', '14.915', '15.282', '15.656', '16.036', '16.423', '16.818', '17.219', '17.628', '18.044', '18.467', '18.898', '19.337', '19.783', '20.237', '20.698', '21.168', '21.646', '22.132', '22.627', '23.130', '23.641', '24.161', '24.690', '25.228', '25.776', '26.332', '26.898', '27.473', '28.058', '28.653', '29.258', '29.874', '30.499', '31.136', '31.783', '32.442', '33.112', '33.793', '34.487', '35.193', '35.912', '36.645', '37.391', '38.152', '38.929', '39.724', '40.541'].map(p => new FloatUnit({ float: p, unit: 'bar' }))
pressure.push(criticalPoint.pressure)

const enthalpyLiquid = ['146.89', '148.14', '149.40', '150.66', '151.92', '153.18', '154.44', '155.71', '156.98', '158.25', '159.52', '160.79', '162.07', '163.34', '164.62', '165.90', '167.19', '168.47', '169.76', '171.05', '172.34', '173.64', '174.93', '176.23', '177.53', '178.83', '180.14', '181.44', '182.75', '184.07', '185.38', '186.70', '188.02', '189.34', '190.66', '191.99', '193.32', '194.65', '195.98', '197.32', '198.66', '200.00', '201.34', '202.69', '204.04', '205.40', '206.75', '208.11', '209.47', '210.84', '212.21', '213.58', '214.95', '216.33', '217.71', '219.09', '220.48', '221.87', '223.26', '224.66', '226.06', '227.47', '228.88', '230.29', '231.70', '233.12', '234.55', '235.97', '237.40', '238.84', '240.28', '241.72', '243.17', '244.62', '246.08', '247.54', '249.01', '250.48', '251.95', '253.43', '254.92', '256.41', '257.91', '259.41', '260.91', '262.43', '263.94', '265.47', '267.00', '268.53', '270.07', '271.62', '273.18', '274.74', '276.31', '277.89', '279.47', '281.06', '282.66', '284.27', '285.88', '287.50', '289.14', '290.78', '292.43', '294.09', '295.76', '297.44', '299.14', '300.84', '302.55', '304.28', '306.02', '307.78', '309.55', '311.33', '313.13', '314.94', '316.78', '318.63', '320.50', '322.39', '324.30', '326.24', '328.21', '330.20', '332.22', '334.28', '336.38', '338.51', '340.69', '342.93', '345.22', '347.59', '350.03', '352.58', '355.25', '358.07', '361.12', '364.47', '368.34', '373.30', '384.35'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' }))
enthalpyLiquid.push(criticalPoint.enthalpy)

const enthalpyVapor = ['373.37', '374.00', '374.64', '375.27', '375.90', '376.54', '377.17', '377.80', '378.43', '379.06', '379.69', '380.32', '380.95', '381.57', '382.20', '382.82', '383.45', '384.07', '384.69', '385.32', '385.94', '386.55', '387.17', '387.79', '388.40', '389.02', '389.63', '390.24', '390.85', '391.46', '392.06', '392.66', '393.27', '393.87', '394.47', '395.06', '395.66', '396.25', '396.84', '397.43', '398.02', '398.60', '399.19', '399.77', '400.34', '400.92', '401.49', '402.06', '402.63', '403.20', '403.76', '404.32', '404.88', '405.43', '405.98', '406.53', '407.07', '407.61', '408.15', '408.69', '409.22', '409.75', '410.27', '410.79', '411.31', '411.82', '412.33', '412.84', '413.34', '413.84', '414.33', '414.82', '415.30', '415.78', '416.26', '416.72', '417.19', '417.65', '418.10', '418.55', '418.99', '419.43', '419.86', '420.28', '420.70', '421.11', '421.52', '421.92', '422.31', '422.69', '423.07', '423.44', '423.80', '424.15', '424.49', '424.83', '425.15', '425.47', '425.77', '426.07', '426.36', '426.63', '426.89', '427.14', '427.38', '427.61', '427.82', '428.02', '428.20', '428.36', '428.52', '428.65', '428.77', '428.86', '428.94', '429.00', '429.03', '429.04', '429.03', '428.98', '428.91', '428.81', '428.68', '428.51', '428.30', '428.05', '427.76', '427.42', '427.02', '426.55', '426.02', '425.42', '424.72', '423.92', '422.99', '421.92', '420.67', '419.18', '417.39', '415.14', '412.16', '407.68', '395.43'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' }))
enthalpyVapor.push(criticalPoint.enthalpy)

const entropyLiquid = ['0.79023', '0.79561', '0.80098', '0.80633', '0.81167', '0.81700', '0.82230', '0.82760', '0.83288', '0.83814', '0.84339', '0.84863', '0.85385', '0.85906', '0.86425', '0.86943', '0.87460', '0.87975', '0.88490', '0.89002', '0.89514', '0.90025', '0.90534', '0.91042', '0.91549', '0.92054', '0.92559', '0.93062', '0.93564', '0.94066', '0.94566', '0.95065', '0.95563', '0.96060', '0.96556', '0.97051', '0.97544', '0.98037', '0.98529', '0.99021', '0.99511', '1.0000', '1.0049', '1.0098', '1.0146', '1.0195', '1.0243', '1.0292', '1.0340', '1.0388', '1.0437', '1.0485', '1.0533', '1.0581', '1.0629', '1.0677', '1.0724', '1.0772', '1.0820', '1.0867', '1.0915', '1.0962', '1.1010', '1.1057', '1.1105', '1.1152', '1.1199', '1.1246', '1.1294', '1.1341', '1.1388', '1.1435', '1.1482', '1.1529', '1.1576', '1.1623', '1.1670', '1.1717', '1.1764', '1.1811', '1.1858', '1.1905', '1.1952', '1.1999', '1.2046', '1.2092', '1.2139', '1.2186', '1.2233', '1.2280', '1.2327', '1.2375', '1.2422', '1.2469', '1.2516', '1.2563', '1.2611', '1.2658', '1.2705', '1.2753', '1.2801', '1.2848', '1.2896', '1.2944', '1.2992', '1.3040', '1.3088', '1.3137', '1.3185', '1.3234', '1.3283', '1.3332', '1.3381', '1.3430', '1.3480', '1.3530', '1.3580', '1.3631', '1.3682', '1.3733', '1.3784', '1.3836', '1.3889', '1.3942', '1.3995', '1.4049', '1.4104', '1.4159', '1.4215', '1.4273', '1.4331', '1.4390', '1.4451', '1.4514', '1.4578', '1.4645', '1.4715', '1.4789', '1.4869', '1.4957', '1.5058', '1.5188', '1.5480'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' }))
entropyLiquid.push(criticalPoint.entropy)

const entropyVapor = ['1.7658', '1.7643', '1.7629', '1.7615', '1.7602', '1.7588', '1.7575', '1.7563', '1.7550', '1.7538', '1.7526', '1.7515', '1.7503', '1.7492', '1.7482', '1.7471', '1.7461', '1.7451', '1.7441', '1.7432', '1.7422', '1.7413', '1.7404', '1.7396', '1.7387', '1.7379', '1.7371', '1.7363', '1.7355', '1.7348', '1.7341', '1.7334', '1.7327', '1.7320', '1.7313', '1.7307', '1.7300', '1.7294', '1.7288', '1.7282', '1.7276', '1.7271', '1.7265', '1.7260', '1.7255', '1.7250', '1.7245', '1.7240', '1.7235', '1.7230', '1.7226', '1.7221', '1.7217', '1.7212', '1.7208', '1.7204', '1.7200', '1.7196', '1.7192', '1.7188', '1.7184', '1.7180', '1.7177', '1.7173', '1.7169', '1.7166', '1.7162', '1.7159', '1.7155', '1.7152', '1.7148', '1.7145', '1.7142', '1.7138', '1.7135', '1.7131', '1.7128', '1.7124', '1.7121', '1.7118', '1.7114', '1.7111', '1.7107', '1.7103', '1.7100', '1.7096', '1.7092', '1.7089', '1.7085', '1.7081', '1.7077', '1.7072', '1.7068', '1.7064', '1.7059', '1.7055', '1.7050', '1.7045', '1.7040', '1.7035', '1.7030', '1.7024', '1.7019', '1.7013', '1.7006', '1.7000', '1.6993', '1.6987', '1.6979', '1.6972', '1.6964', '1.6956', '1.6948', '1.6939', '1.6929', '1.6920', '1.6909', '1.6899', '1.6887', '1.6876', '1.6863', '1.6850', '1.6836', '1.6821', '1.6806', '1.6789', '1.6771', '1.6752', '1.6732', '1.6710', '1.6687', '1.6662', '1.6634', '1.6604', '1.6571', '1.6534', '1.6492', '1.6445', '1.6389', '1.6322', '1.6235', '1.6109', '1.5776'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' }))
entropyVapor.push(criticalPoint.entropy)

module.exports.boilingData = {
	temperature,
	pressure,
	enthalpyLiquid,
	enthalpyVapor,
	entropyLiquid,
	entropyVapor,
}

// General data by pressure.
module.exports.dataByPressure = [
	{
		pressure: new FloatUnit({ float: '0.5', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.454', '-40.454', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.68', '141.89', '147.57', '373.71', '374.05', '377.80', '381.58', '385.38', '389.21', '393.08', '396.99', '400.94', '404.92', '408.95', '413.02', '417.12', '421.27', '425.46', '429.69', '433.96', '438.28', '442.63', '447.03', '451.46', '455.94', '460.46', '465.02', '469.62', '474.26', '478.95', '483.67', '488.43', '493.24', '498.08', '502.96', '507.88', '512.85', '517.85', '522.89', '527.97', '533.09', '538.25', '543.45', '548.68', '553.96'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74098', '0.76851', '0.79317', '1.7650', '1.7665', '1.7824', '1.7980', '1.8135', '1.8288', '1.8440', '1.8590', '1.8738', '1.8885', '1.9031', '1.9176', '1.9320', '1.9463', '1.9605', '1.9745', '1.9885', '2.0024', '2.0162', '2.0299', '2.0435', '2.0571', '2.0705', '2.0839', '2.0972', '2.1105', '2.1236', '2.1367', '2.1497', '2.1627', '2.1756', '2.1884', '2.2012', '2.2139', '2.2265', '2.2391', '2.2516', '2.2641', '2.2765', '2.2889', '2.3012', '2.3134'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '0.63', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.971', '-35.971', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.69', '141.90', '148.15', '153.22', '376.55', '377.29', '381.11', '384.95', '388.82', '392.71', '396.65', '400.61', '404.62', '408.66', '412.74', '416.86', '421.02', '425.23', '429.47', '433.75', '438.07', '442.44', '446.84', '451.28', '455.77', '460.30', '464.86', '469.47', '474.12', '478.80', '483.53', '488.30', '493.11', '497.96', '502.84', '507.77', '512.74', '517.74', '522.79', '527.87', '532.99', '538.15', '543.35', '548.59', '553.87'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74097', '0.76849', '0.79559', '0.81715', '1.7588', '1.7619', '1.7778', '1.7934', '1.8088', '1.8241', '1.8391', '1.8541', '1.8689', '1.8835', '1.8981', '1.9125', '1.9268', '1.9410', '1.9552', '1.9692', '1.9831', '1.9969', '2.0106', '2.0243', '2.0379', '2.0513', '2.0647', '2.0781', '2.0913', '2.1045', '2.1176', '2.1307', '2.1436', '2.1565', '2.1694', '2.1821', '2.1949', '2.2075', '2.2201', '2.2326', '2.2451', '2.2575', '2.2699', '2.2822', '2.2944'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '0.8', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-31.115', '-31.115', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.69', '141.90', '148.15', '154.45', '159.37', '379.62', '380.48', '384.38', '388.29', '392.22', '396.19', '400.18', '404.21', '408.28', '412.38', '416.52', '420.70', '424.92', '429.17', '433.47', '437.80', '442.18', '446.59', '451.05', '455.54', '460.08', '464.65', '469.27', '473.92', '478.62', '483.35', '488.13', '492.94', '497.79', '502.69', '507.62', '512.59', '517.60', '522.65', '527.74', '532.86', '538.03', '543.23', '548.47', '553.75'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74094', '0.76846', '0.79557', '0.82228', '0.84278', '1.7528', '1.7563', '1.7722', '1.7878', '1.8032', '1.8184', '1.8334', '1.8483', '1.8631', '1.8777', '1.8922', '1.9066', '1.9208', '1.9350', '1.9490', '1.9630', '1.9768', '1.9906', '2.0043', '2.0179', '2.0314', '2.0448', '2.0582', '2.0715', '2.0847', '2.0978', '2.1108', '2.1238', '2.1367', '2.1496', '2.1624', '2.1751', '2.1878', '2.2004', '2.2129', '2.2254', '2.2378', '2.2502', '2.2625', '2.2748'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '1', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-26.361', '-26.361', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.70', '141.91', '148.16', '154.46', '160.80', '165.44', '382.60', '383.68', '387.65', '391.63', '395.64', '399.67', '403.73', '407.82', '411.95', '416.11', '420.31', '424.55', '428.82', '433.14', '437.49', '441.88', '446.30', '450.77', '455.28', '459.82', '464.41', '469.03', '473.70', '478.40', '483.14', '487.92', '492.74', '497.60', '502.50', '507.44', '512.42', '517.43', '522.49', '527.58', '532.71', '537.88', '543.09', '548.33', '553.62'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74092', '0.76844', '0.79554', '0.82225', '0.84860', '0.86756', '1.7475', '1.7519', '1.7677', '1.7833', '1.7986', '1.8138', '1.8288', '1.8437', '1.8584', '1.8730', '1.8874', '1.9017', '1.9160', '1.9301', '1.9441', '1.9580', '1.9718', '1.9855', '1.9991', '2.0127', '2.0261', '2.0395', '2.0528', '2.0660', '2.0792', '2.0923', '2.1053', '2.1182', '2.1311', '2.1439', '2.1566', '2.1693', '2.1819', '2.1945', '2.2070', '2.2194', '2.2318', '2.2441', '2.2564'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '1.25', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-21.381', '-21.381', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.71', '141.92', '148.17', '154.47', '160.81', '167.19', '171.85', '385.70', '386.82', '390.87', '394.93', '399.01', '403.12', '407.25', '411.41', '415.60', '419.83', '424.09', '428.38', '432.72', '437.09', '441.49', '445.94', '450.42', '454.94', '459.50', '464.10', '468.73', '473.41', '478.12', '482.88', '487.67', '492.50', '497.36', '502.27', '507.22', '512.20', '517.22', '522.28', '527.38', '532.52', '537.70', '542.91', '548.16', '553.45'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74088', '0.76840', '0.79550', '0.82222', '0.84856', '0.87457', '0.89319', '1.7426', '1.7470', '1.7629', '1.7785', '1.7938', '1.8090', '1.8240', '1.8388', '1.8535', '1.8680', '1.8824', '1.8967', '1.9109', '1.9249', '1.9389', '1.9528', '1.9665', '1.9802', '1.9938', '2.0073', '2.0207', '2.0340', '2.0473', '2.0605', '2.0736', '2.0866', '2.0996', '2.1124', '2.1253', '2.1380', '2.1507', '2.1634', '2.1759', '2.1884', '2.2009', '2.2133', '2.2256', '2.2379'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '1.6', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.588', '-15.588', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.72', '141.93', '148.18', '154.48', '160.82', '167.21', '173.64', '179.37', '389.27', '389.76', '393.91', '398.07', '402.24', '406.42', '410.63', '414.87', '419.13', '423.43', '427.76', '432.12', '436.52', '440.95', '445.42', '449.93', '454.47', '459.05', '463.66', '468.32', '473.01', '477.74', '482.50', '487.31', '492.15', '497.03', '501.95', '506.90', '511.90', '516.93', '522.00', '527.11', '532.25', '537.44', '542.66', '547.91', '553.21'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74084', '0.76835', '0.79545', '0.82216', '0.84851', '0.87451', '0.90020', '0.92262', '1.7376', '1.7395', '1.7554', '1.7711', '1.7865', '1.8016', '1.8166', '1.8315', '1.8461', '1.8607', '1.8751', '1.8894', '1.9035', '1.9176', '1.9315', '1.9453', '1.9591', '1.9727', '1.9862', '1.9997', '2.0131', '2.0264', '2.0396', '2.0527', '2.0658', '2.0788', '2.0917', '2.1046', '2.1173', '2.1301', '2.1427', '2.1553', '2.1678', '2.1803', '2.1927', '2.2051', '2.2174'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '2', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.076', '-10.076', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.74', '141.95', '148.20', '154.49', '160.83', '167.22', '173.66', '180.15', '186.60', '392.62', '392.68', '396.94', '401.20', '405.45', '409.73', '414.02', '418.33', '422.67', '427.04', '431.44', '435.87', '440.33', '444.83', '449.36', '453.92', '458.52', '463.16', '467.83', '472.54', '477.29', '482.07', '486.89', '491.75', '496.64', '501.57', '506.54', '511.55', '516.59', '521.67', '526.79', '531.94', '537.14', '542.37', '547.63', '552.94'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74078', '0.76830', '0.79540', '0.82210', '0.84845', '0.87445', '0.90013', '0.92553', '0.95027', '1.7334', '1.7337', '1.7497', '1.7654', '1.7808', '1.7961', '1.8111', '1.8259', '1.8406', '1.8551', '1.8695', '1.8838', '1.8979', '1.9120', '1.9259', '1.9397', '1.9534', '1.9670', '1.9805', '1.9939', '2.0073', '2.0206', '2.0337', '2.0468', '2.0599', '2.0728', '2.0857', '2.0985', '2.1113', '2.1239', '2.1366', '2.1491', '2.1616', '2.1740', '2.1864', '2.1987'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '2.5', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '-4.2837', '-4.2837', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.76', '141.97', '148.22', '154.51', '160.85', '167.24', '173.67', '180.16', '186.71', '193.32', '194.27', '396.08', '399.83', '404.20', '408.55', '412.92', '417.30', '421.70', '426.12', '430.56', '435.04', '439.54', '444.07', '448.64', '453.23', '457.86', '462.53', '467.23', '471.96', '476.73', '481.53', '486.37', '491.25', '496.16', '501.11', '506.09', '511.11', '516.17', '521.26', '526.39', '531.56', '536.76', '542.00', '547.28', '552.59'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74071', '0.76823', '0.79532', '0.82203', '0.84837', '0.87437', '0.90005', '0.92544', '0.95056', '0.97543', '0.97898', '1.7296', '1.7434', '1.7593', '1.7748', '1.7901', '1.8051', '1.8200', '1.8347', '1.8493', '1.8637', '1.8779', '1.8921', '1.9061', '1.9200', '1.9338', '1.9475', '1.9611', '1.9746', '1.9880', '2.0013', '2.0145', '2.0277', '2.0407', '2.0537', '2.0667', '2.0795', '2.0923', '2.1050', '2.1177', '2.1302', '2.1428', '2.1552', '2.1676', '2.1800'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '3.2', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '2.4768', '2.4768', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.79', '141.99', '148.24', '154.54', '160.87', '167.26', '173.69', '180.18', '186.73', '193.34', '200.01', '203.34', '400.04', '402.33', '406.83', '411.32', '415.80', '420.29', '424.79', '429.31', '433.85', '438.41', '443.00', '447.61', '452.25', '456.93', '461.63', '466.36', '471.13', '475.93', '480.77', '485.63', '490.54', '495.47', '500.44', '505.45', '510.49', '515.57', '520.68', '525.83', '531.02', '536.24', '541.49', '546.78', '552.11'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74062', '0.76813', '0.79522', '0.82193', '0.84826', '0.87426', '0.89993', '0.92532', '0.95043', '0.97530', '0.99995', '1.0121', '1.7257', '1.7340', '1.7501', '1.7658', '1.7812', '1.7964', '1.8113', '1.8261', '1.8407', '1.8552', '1.8695', '1.8837', '1.8977', '1.9116', '1.9254', '1.9391', '1.9527', '1.9662', '1.9796', '1.9929', '2.0062', '2.0193', '2.0324', '2.0453', '2.0583', '2.0711', '2.0838', '2.0965', '2.1092', '2.1217', '2.1342', '2.1467', '2.1590'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '4', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '8.9306', '8.9306', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.82', '142.02', '148.27', '154.56', '160.90', '167.29', '173.72', '180.21', '186.75', '193.36', '200.02', '206.76', '212.11', '403.72', '404.72', '409.38', '414.01', '418.62', '423.22', '427.83', '432.45', '437.08', '441.74', '446.41', '451.11', '455.83', '460.58', '465.36', '470.17', '475.01', '479.88', '484.78', '489.72', '494.68', '499.68', '504.71', '509.78', '514.88', '520.02', '525.19', '530.39', '535.63', '540.91', '546.21', '551.56'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74051', '0.76802', '0.79511', '0.82181', '0.84814', '0.87413', '0.89980', '0.92518', '0.95029', '0.97515', '0.99979', '1.0242', '1.0433', '1.7226', '1.7261', '1.7425', '1.7584', '1.7740', '1.7893', '1.8044', '1.8192', '1.8339', '1.8484', '1.8628', '1.8770', '1.8911', '1.9050', '1.9188', '1.9325', '1.9461', '1.9597', '1.9731', '1.9864', '1.9996', '2.0127', '2.0258', '2.0387', '2.0516', '2.0645', '2.0772', '2.0899', '2.1025', '2.1150', '2.1275', '2.1399'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '5', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '15.735', '15.735', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.86', '142.06', '148.31', '154.60', '160.94', '167.32', '173.75', '180.24', '186.78', '193.38', '200.05', '206.78', '213.59', '220.48', '221.50', '407.47', '411.61', '416.40', '421.16', '425.90', '430.63', '435.37', '440.11', '444.87', '449.64', '454.43', '459.25', '464.09', '468.95', '473.84', '478.76', '483.70', '488.68', '493.68', '498.72', '503.78', '508.88', '514.01', '519.18', '524.37', '529.60', '534.87', '540.17', '545.50', '550.86'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74038', '0.76788', '0.79496', '0.82166', '0.84798', '0.87397', '0.89964', '0.92501', '0.95011', '0.97496', '0.99959', '1.0240', '1.0483', '1.0724', '1.0759', '1.7197', '1.7339', '1.7501', '1.7659', '1.7814', '1.7967', '1.8117', '1.8265', '1.8411', '1.8555', '1.8698', '1.8839', '1.8979', '1.9118', '1.9256', '1.9392', '1.9527', '1.9661', '1.9795', '1.9927', '2.0058', '2.0189', '2.0318', '2.0447', '2.0575', '2.0703', '2.0829', '2.0955', '2.1081', '2.1205'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '6.3', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '23.178', '23.178', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.91', '142.11', '148.36', '154.65', '160.98', '167.36', '173.79', '180.28', '186.82', '193.41', '200.08', '206.81', '213.61', '220.50', '227.47', '231.96', '411.40', '413.25', '418.27', '423.22', '428.14', '433.03', '437.91', '442.79', '447.67', '452.56', '457.46', '462.38', '467.32', '472.28', '477.26', '482.27', '487.30', '492.36', '497.44', '502.55', '507.70', '512.87', '518.07', '523.30', '528.57', '533.87', '539.19', '544.56', '549.95'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.74020', '0.76770', '0.79478', '0.82147', '0.84779', '0.87376', '0.89942', '0.92478', '0.94987', '0.97471', '0.99933', '1.0237', '1.0480', '1.0721', '1.0961', '1.1113', '1.7169', '1.7231', '1.7398', '1.7560', '1.7718', '1.7873', '1.8025', '1.8175', '1.8323', '1.8468', '1.8612', '1.8755', '1.8896', '1.9035', '1.9173', '1.9310', '1.9446', '1.9580', '1.9714', '1.9847', '1.9978', '2.0109', '2.0239', '2.0368', '2.0496', '2.0623', '2.0750', '2.0876', '2.1001'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '8', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '31.327', '31.327', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['135.97', '142.18', '148.42', '154.71', '161.04', '167.42', '173.85', '180.33', '186.86', '193.46', '200.12', '206.84', '213.64', '220.52', '227.49', '234.55', '241.72', '243.65', '415.46', '419.37', '424.59', '429.74', '434.84', '439.91', '444.95', '449.99', '455.03', '460.07', '465.12', '470.18', '475.25', '480.34', '485.45', '490.58', '495.74', '500.91', '506.12', '511.34', '516.60', '521.88', '527.20', '532.54', '537.91', '543.31', '548.74'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.73997', '0.76746', '0.79454', '0.82121', '0.84752', '0.87349', '0.89914', '0.92449', '0.94957', '0.97439', '0.99899', '1.0234', '1.0476', '1.0717', '1.0957', '1.1196', '1.1434', '1.1497', '1.7140', '1.7268', '1.7436', '1.7599', '1.7758', '1.7914', '1.8067', '1.8217', '1.8365', '1.8510', '1.8654', '1.8797', '1.8937', '1.9076', '1.9214', '1.9351', '1.9486', '1.9621', '1.9754', '1.9886', '2.0017', '2.0147', '2.0277', '2.0405', '2.0533', '2.0660', '2.0786'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '10', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '39.388', '39.388', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['136.05', '142.25', '148.50', '154.78', '161.11', '167.48', '173.91', '180.39', '186.92', '193.51', '200.16', '206.88', '213.68', '220.55', '227.50', '234.56', '241.72', '249.00', '255.50', '419.16', '419.86', '425.44', '430.88', '436.24', '441.53', '446.78', '452.00', '457.21', '462.40', '467.59', '472.79', '477.99', '483.21', '488.43', '493.67', '498.93', '504.21', '509.51', '514.83', '520.18', '525.55', '530.95', '536.37', '541.82', '547.30'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.73970', '0.76719', '0.79425', '0.82092', '0.84722', '0.87318', '0.89881', '0.92415', '0.94921', '0.97402', '0.99860', '1.0230', '1.0472', '1.0712', '1.0952', '1.1190', '1.1428', '1.1667', '1.1876', '1.7113', '1.7135', '1.7312', '1.7482', '1.7646', '1.7806', '1.7962', '1.8116', '1.8266', '1.8414', '1.8561', '1.8705', '1.8847', '1.8988', '1.9127', '1.9264', '1.9401', '1.9536', '1.9670', '1.9803', '1.9934', '2.0065', '2.0195', '2.0324', '2.0452', '2.0579'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '12.5', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '47.909', '47.909', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['136.15', '142.35', '148.59', '154.87', '161.20', '167.57', '173.99', '180.46', '186.99', '193.58', '200.22', '206.94', '213.72', '220.58', '227.53', '234.57', '241.71', '248.97', '256.37', '263.92', '268.39', '422.66', '425.19', '431.07', '436.79', '442.39', '447.91', '453.37', '458.79', '464.18', '469.55', '474.92', '480.28', '485.64', '491.00', '496.37', '501.76', '507.15', '512.57', '518.00', '523.45', '528.92', '534.41', '539.93', '545.47'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.73937', '0.76684', '0.79389', '0.82055', '0.84684', '0.87278', '0.89840', '0.92372', '0.94876', '0.97355', '0.99811', '1.0225', '1.0466', '1.0707', '1.0946', '1.1184', '1.1421', '1.1659', '1.1897', '1.2136', '1.2276', '1.7081', '1.7160', '1.7340', '1.7513', '1.7680', '1.7842', '1.8000', '1.8155', '1.8306', '1.8455', '1.8602', '1.8746', '1.8889', '1.9030', '1.9169', '1.9307', '1.9444', '1.9579', '1.9713', '1.9845', '1.9977', '2.0108', '2.0237', '2.0366'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '16', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '57.906', '57.906', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['136.29', '142.48', '148.72', '155.00', '161.32', '167.69', '174.10', '180.57', '187.09', '193.67', '200.31', '207.01', '213.78', '220.63', '227.56', '234.59', '241.71', '248.94', '256.31', '263.82', '271.51', '279.41', '284.11', '426.04', '428.84', '435.25', '441.40', '447.37', '453.22', '458.97', '464.66', '470.30', '475.91', '481.49', '487.06', '492.61', '498.16', '503.71', '509.27', '514.83', '520.40', '525.99', '531.59', '537.21', '542.84'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.73890', '0.76636', '0.79340', '0.82004', '0.84631', '0.87223', '0.89783', '0.92312', '0.94814', '0.97290', '0.99743', '1.0218', '1.0459', '1.0699', '1.0937', '1.1175', '1.1412', '1.1648', '1.1885', '1.2123', '1.2363', '1.2606', '1.2748', '1.7036', '1.7120', '1.7311', '1.7491', '1.7664', '1.7831', '1.7993', '1.8150', '1.8305', '1.8456', '1.8605', '1.8751', '1.8895', '1.9037', '1.9177', '1.9316', '1.9453', '1.9589', '1.9723', '1.9856', '1.9988', '2.0119'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '20', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '67.481', '67.481', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['136.44', '142.64', '148.87', '155.14', '161.46', '167.82', '174.23', '180.69', '187.21', '193.77', '200.40', '207.10', '213.86', '220.69', '227.61', '234.61', '241.71', '248.92', '256.25', '263.72', '271.35', '279.18', '287.26', '295.64', '299.95', '428.28', '432.06', '439.12', '445.77', '452.17', '458.38', '464.47', '470.45', '476.36', '482.21', '488.02', '493.80', '499.56', '505.31', '511.04', '516.78', '522.51', '528.24', '533.99', '539.74'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.73837', '0.76581', '0.79283', '0.81946', '0.84570', '0.87161', '0.89718', '0.92245', '0.94744', '0.97217', '0.99666', '1.0209', '1.0450', '1.0690', '1.0928', '1.1164', '1.1401', '1.1636', '1.1872', '1.2109', '1.2347', '1.2588', '1.2832', '1.3082', '1.3209', '1.6976', '1.7086', '1.7291', '1.7481', '1.7660', '1.7833', '1.7999', '1.8160', '1.8318', '1.8471', '1.8622', '1.8770', '1.8916', '1.9059', '1.9201', '1.9340', '1.9478', '1.9614', '1.9749', '1.9883'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '25', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '77.577', '77.577', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['136.64', '142.83', '149.06', '155.32', '161.64', '167.99', '174.39', '180.85', '187.35', '193.91', '200.53', '207.21', '213.95', '220.77', '227.67', '234.65', '241.72', '248.89', '256.19', '263.61', '271.18', '278.94', '286.91', '295.15', '303.75', '312.86', '317.84', '429.01', '433.37', '441.51', '448.94', '455.93', '462.64', '469.14', '475.49', '481.73', '487.89', '493.98', '500.01', '506.01', '511.98', '517.93', '523.87', '529.79', '535.71'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.73771', '0.76513', '0.79213', '0.81873', '0.84496', '0.87083', '0.89637', '0.92161', '0.94657', '0.97126', '0.99571', '1.0199', '1.0440', '1.0678', '1.0916', '1.1152', '1.1387', '1.1622', '1.1857', '1.2092', '1.2328', '1.2566', '1.2807', '1.3053', '1.3305', '1.3569', '1.3711', '1.6881', '1.7005', '1.7234', '1.7440', '1.7631', '1.7812', '1.7985', '1.8152', '1.8314', '1.8471', '1.8625', '1.8776', '1.8924', '1.9069', '1.9212', '1.9353', '1.9492', '1.9630'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '32', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '89.331', '89.331', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['136.92', '143.10', '149.32', '155.58', '161.88', '168.23', '174.62', '181.07', '187.56', '194.10', '200.70', '207.37', '214.09', '220.89', '227.76', '234.71', '241.74', '248.88', '256.12', '263.48', '270.98', '278.64', '286.49', '294.57', '302.93', '311.68', '320.97', '331.14', '341.43', '425.83', '427.75', '439.26', '448.48', '456.68', '464.28', '471.50', '478.45', '485.21', '491.81', '498.30', '504.70', '511.04', '517.31', '523.55', '529.75'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.73679', '0.76419', '0.79116', '0.81772', '0.84392', '0.86975', '0.89526', '0.92045', '0.94536', '0.97000', '0.99439', '1.0186', '1.0425', '1.0663', '1.0900', '1.1135', '1.1369', '1.1602', '1.1835', '1.2068', '1.2302', '1.2537', '1.2775', '1.3015', '1.3261', '1.3514', '1.3779', '1.4065', '1.4350', '1.6679', '1.6732', '1.7047', '1.7295', '1.7514', '1.7714', '1.7901', '1.8079', '1.8249', '1.8414', '1.8574', '1.8730', '1.8883', '1.9032', '1.9178', '1.9322'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '40', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '100.34', '100.34', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['137.24', '143.41', '149.62', '155.88', '162.17', '168.51', '174.89', '181.32', '187.80', '194.33', '200.91', '207.55', '214.26', '221.03', '227.87', '234.79', '241.79', '248.88', '256.07', '263.37', '270.79', '278.36', '286.09', '294.01', '302.17', '310.62', '319.46', '328.83', '339.06', '350.96', '370.70', '375.57', '405.38', '433.60', '446.26', '456.28', '465.10', '473.23', '480.90', '488.26', '495.38', '502.32', '509.12', '515.82', '522.43'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.73575', '0.76311', '0.79005', '0.81658', '0.84274', '0.86854', '0.89400', '0.91914', '0.94400', '0.96858', '0.99291', '1.0170', '1.0409', '1.0646', '1.0881', '1.1115', '1.1348', '1.1580', '1.1812', '1.2043', '1.2274', '1.2507', '1.2740', '1.2977', '1.3216', '1.3461', '1.3713', '1.3976', '1.4260', '1.4585', '1.5117', '1.5247', '1.6046', '1.6798', '1.7131', '1.7391', '1.7616', '1.7822', '1.8013', '1.8195', '1.8368', '1.8535', '1.8697', '1.8854', '1.9008'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
	{
		pressure: new FloatUnit({ float: '50', unit: 'bar' }).makeExact(),
		temperature: ['-50.000', '-45.000', '-40.000', '-35.000', '-30.000', '-25.000', '-20.000', '-15.000', '-10.000', '-5.0000', '0.0000', '5.0000', '10.000', '15.000', '20.000', '25.000', '30.000', '35.000', '40.000', '45.000', '50.000', '55.000', '60.000', '65.000', '70.000', '75.000', '80.000', '85.000', '90.000', '95.000', '100.00', '105.00', '110.00', '115.00', '120.00', '125.00', '130.00', '135.00', '140.00', '145.00', '150.00', '155.00', '160.00'].map(T => new FloatUnit({ float: T, unit: 'dC' }).makeExact()),
		enthalpy: ['137.64', '143.80', '150.01', '156.25', '162.53', '168.86', '175.23', '181.64', '188.10', '194.61', '201.18', '207.80', '214.48', '221.22', '228.03', '234.91', '241.86', '248.90', '256.04', '263.27', '270.61', '278.07', '285.68', '293.45', '301.40', '309.59', '318.05', '326.86', '336.15', '346.12', '357.21', '370.56', '391.02', '421.27', '440.09', '453.04', '463.66', '473.05', '481.69', '489.83', '497.61', '505.12', '512.42'].map(h => new FloatUnit({ float: h, unit: 'kJ/kg' })),
		entropy: ['0.73446', '0.76178', '0.78868', '0.81518', '0.84128', '0.86703', '0.89244', '0.91753', '0.94233', '0.96684', '0.99110', '1.0151', '1.0389', '1.0625', '1.0859', '1.1092', '1.1323', '1.1554', '1.1783', '1.2012', '1.2241', '1.2471', '1.2701', '1.2932', '1.3166', '1.3402', '1.3644', '1.3892', '1.4149', '1.4422', '1.4721', '1.5076', '1.5613', '1.6398', '1.6880', '1.7207', '1.7472', '1.7704', '1.7914', '1.8110', '1.8295', '1.8472', '1.8641'].map(s => new FloatUnit({ float: s, unit: 'kJ/kg * K' })),
	},
]
